import anime from 'animejs';

export const initMobileNavigation = () => {
    function MobileNavigation() {
        // VARS
        const $this = this;
        const hamburger = document.querySelector('[data-hamburger]');
        const mobileNavigation = document.querySelector('[data-header-nav]');
        if (!mobileNavigation || !hamburger) return;
        const activeClassMenu = 'header__nav--is-open';
        const activeClassHamburger = 'hamburger--active';
        const duration = 400;

        // LISTENERS
        hamburger?.addEventListener('click', handleOnClickHamburger, false);

        // HANDLERS
        function handleOnClickHamburger() {
            toggleMobileMenu();
        }

        // FUNCTIONS & METHODS
        function toggleMobileMenu() {
            $this.toggleMenu();
            $this.toggleHamburger();
        }

        $this.toggleHamburger = () => {
            hamburger.classList.contains(activeClassHamburger) ? $this.closeHamburger() : $this.openHamburger();
        };

        $this.toggleMenu = () => {
            if (mobileNavigation.classList.contains(activeClassMenu)) {
                $this.closeMenu();
            } else {
                $this.openMenu();
            }
        };

        $this.openHamburger = () => {
            hamburger.classList.add(activeClassHamburger);
        };

        $this.closeHamburger = () => {
            hamburger.classList.remove(activeClassHamburger);
        };

        $this.openMenu = () => {
            const height = mobileNavigation.scrollHeight;
            mobileNavigation.classList.add(activeClassMenu);

            anime({
                targets: mobileNavigation,
                easing: 'linear',
                duration: duration,
                complete: function () {
                    mobileNavigation.style.display = 'block';
                },
            });
        };

        $this.closeMenu = () => {
            const height = mobileNavigation.scrollHeight;
            mobileNavigation.style.display = 'none';
            mobileNavigation.classList.remove(activeClassMenu);

            anime({
                targets: mobileNavigation,
                easing: 'linear',
                duration: duration,
            });
        };
    }

    window.mobileMenu = new MobileNavigation();
};
