import { isValidMail } from './helper-function/isValidMail';

export function initCreateAccountEmail() {
    // VARS
    const form = document.querySelector('[data-create-account-form]');
    if (!form) return;
    const agreeCheckbox = document.querySelector('[data-create-account-checkbox]');
    const inputEmail = document.querySelector('[data-create-account-email]');
    const selectorsErrorMessage = {
        email: '[data-error-email]',
        checkbox: '[data-error-checkbox]',
    };
    const classVisible = 'is-visible';
    let isValid = true;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    inputEmail.addEventListener('focus', handleOnFocusEmailInput, false);

    agreeCheckbox.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateForm(event);
    }

    function handleOnChangeAgreeCheckbox() {
        if (this.checked) {
            hideErrorMessage(selectorsErrorMessage.checkbox);
        }
    }

    function handleOnFocusEmailInput() {
        hideErrorMessage(selectorsErrorMessage.email);
    }

    // METHODS & FUNCTIONS
    function validateForm(event) {
        validateEmail();
        validateAgreeCheckbox();

        if (!isValid) {
            event.preventDefault();
            isValid = true;
        }
    }

    function validateEmail() {
        if (isValidMail(inputEmail.value.trim())) {
            hideErrorMessage(selectorsErrorMessage.email);
        } else {
            isValid = false;
            showErrorMessage(selectorsErrorMessage.email);
        }
    }

    function validateAgreeCheckbox() {
        if (agreeCheckbox.checked) {
            hideErrorMessage(selectorsErrorMessage.checkbox);
        } else {
            isValid = false;
            showErrorMessage(selectorsErrorMessage.checkbox);
        }
    }

    function showErrorMessage(selector) {
        document.querySelector(selector).classList.add(classVisible);
    }

    function hideErrorMessage(selector) {
        document.querySelector(selector).classList.remove(classVisible);
    }
}
