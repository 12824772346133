export const initStickyTableHeader = () => {
    // VARS
    const tableHeader = document.querySelector('[data-table-header]');
    const stickyTableHeader = document.querySelector('[data-sticky-table-header]');
    if (!tableHeader && !stickyTableHeader) return;
    const table = document.querySelector('[data-table]');
    let coords = getCoords(table);
    let tableHeaderCoords = tableHeader.getBoundingClientRect();

    // LISTENERS AND CALLS
    window.addEventListener('scroll', handleOnScrollWindow, false);
    window.addEventListener('resize', handleOnResizeWindow, false);

    toggleSticky();

    // HANDLERS
    function handleOnScrollWindow() {
        toggleSticky();
    }

    function handleOnResizeWindow() {
        setCurrentStyle();
    }

    // FUNCTIONS
    function toggleSticky() {
        if (window.scrollY > coords.top + 20 && window.scrollY + 220 < coords.bottom) {
            setStickyStyle();
        } else {
            deleteStickyStyle();
        }
    }

    function setStickyStyle() {
        stickyTableHeader.classList.add('is-sticky');
        stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
        stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
    }

    function deleteStickyStyle() {
        stickyTableHeader.classList.remove('is-sticky');
        stickyTableHeader.style.left = '';
        stickyTableHeader.style.width = '';
    }

    function setCurrentStyle() {
        coords = getCoords(table);
        tableHeaderCoords = tableHeader.getBoundingClientRect();
        stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
        stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
    }

    function getCoords(elem) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top + window.scrollY,
            left: box.left + window.scrollY,
            bottom: box.bottom + window.scrollY,
            right: box.right + window.scrollY,
        };
    }
};
