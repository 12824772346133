import { scrollTo } from './helper-function/scrollTo';

export const initScrollToContentFeaturePage = () => {
    // VARS
    const selector = '[data-feature-page]';
    const content = document.querySelector(`${selector}`);
    if (!content || window.innerWidth > 1023) return;

    // INITS
    scrollTo(selector, -100);
};
