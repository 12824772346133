import { scrollLocker } from './tools/scrollLocker';
import { initAccordions } from './components/initAccordions';
import { initShowMoreContent } from './components/initShowMoreContent';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileNavigation } from './components/initMobileNavigation';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initScrollToContentFeaturePage } from './components/initScrollToContentFeaturePage';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', () => {
    initPageLoader();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initChunkPageBeforeCheckout();
    initHashEmail();
    scrollLocker();
    initEmailAutocompleteDropdown();
    initMobileFeatureMenu();
    initMobileNavigation();
    initAccordions();
    initShowMoreContent();
    initScrollToAnchor();
    initForms();
    initStickyTableHeader();
    initRenderPricesPage();
    initLangSwitcher();
    initScrollToContentFeaturePage();
    initRippleAnimation();
});

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});
